'use client';

import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useMemo } from 'react';

export default function NavigationEvents() {
  const pathname = usePathname();
  const router = useRouter();

  useMemo(() => {
    router.prefetch = async () => {};
  }, [router]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('set', 'page_path', pathname!);
      window.gtag('event', 'page_view');
    }
  }, [pathname]);

  return null;
}
