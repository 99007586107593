import React from 'react';
import LinkIcon from '@mui/icons-material/Link';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Box, BoxProps } from '@mui/material';

export type SocialLinksProps = {
  websiteURL?: string | null;
  facebookURL?: string | null;
  twitterURL?: string | null;
  instagramURL?: string | null;
  linkedinURL?: string | null;
} & BoxProps;

export default function SocialLinks({
  websiteURL,
  facebookURL,
  twitterURL,
  instagramURL,
  linkedinURL,
  gap = 2,
  ...boxProps
}: SocialLinksProps) {
  return (
    <Box display="flex" gap={gap} alignItems="center" {...boxProps}>
      {websiteURL && (
        <a href={websiteURL} target="_blank" rel="noreferrer nofollow">
          <LinkIcon sx={{ display: 'block' }} />
        </a>
      )}
      {facebookURL && (
        <a href={facebookURL} target="_blank" rel="noreferrer nofollow">
          <FacebookIcon sx={{ display: 'block' }} />
        </a>
      )}
      {twitterURL && (
        <a href={twitterURL} target="_blank" rel="noreferrer nofollow">
          <TwitterIcon sx={{ display: 'block' }} />
        </a>
      )}
      {instagramURL && (
        <a href={instagramURL} target="_blank" rel="noreferrer nofollow">
          <InstagramIcon sx={{ display: 'block' }} />
        </a>
      )}
      {linkedinURL && (
        <a href={linkedinURL} target="_blank" rel="noreferrer nofollow">
          <LinkedInIcon sx={{ display: 'block' }} />
        </a>
      )}
    </Box>
  );
}
