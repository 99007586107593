'use client';

import { SnackbarProvider } from 'notistack';
import { styled } from '@mui/material';

export const StyledSnackbarProvider = styled(SnackbarProvider)(
  ({ theme }) => `
  &.SnackbarItem-variantSuccess: {
    background-color: ${theme.palette.success.main};
  }
  &.SnackbarItem-variantError: {
    background-color: ${theme.palette.error.main};
  }
  &.SnackbarItem-variantWarning: {
    background-color: ${theme.palette.warning.main};
  }
  &.SnackbarItem-variantInfo: {
    background-color: ${theme.palette.info.main};
  }
`,
);
