import { createTheme } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';
import {
  primary,
  green,
  blue,
  gold,
  warning,
  error,
  success,
  info,
  grey,
  textColors,
  neutral,
} from './colors';

export const publicMotivoTheme = createTheme({
  palette: {
    primary,
    green,
    blue,
    gold,
    warning,
    error,
    success,
    info,
    grey,
    text: textColors,
    neutral,
  },
  typography: {
    fontFamily: ['proxima-nova', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: 48,
      lineHeight: 1.1,
      fontWeight: 600,
      fontFamily: 'Lora, serif',
    },
    h2: {
      fontSize: 30,
      lineHeight: 1.1,
      fontWeight: 600,
      fontFamily: 'Lora, serif',
    },
    h3: {
      fontSize: 24,
      lineHeight: 1.1,
      fontWeight: 600,
      fontFamily: 'Lora, serif',
    },
    h4: {
      fontSize: 18,
      lineHeight: 1.1,
      fontWeight: 600,
      fontFamily: 'Lora, serif',
    },
    h5: {
      fontSize: 16,
      lineHeight: 1.1,
      fontWeight: 600,
      fontFamily: 'Lora, serif',
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.5,
      color: textColors.secondary,
    },
    body3: {
      fontSize: 18,
      lineHeight: 1.5,
      fontWeight: 600,
    },
  },
  components: {
    MuiAccordion: {
      defaultProps: {
        square: true,
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          padding: 8,
          '::before': {
            display: 'none',
          },
          ':not(:first-of-type)': {
            borderTop: 0,
          },
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        position: 'static',
        color: 'transparent',
      },
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: 'none',
          borderBottom: '1px solid #C7C7C7',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          height: '120px',
          width: '120px',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: textColors.primary,
          color: '#fff',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          padding: '16px 24px',
          fontWeight: 600,
          textTransform: 'none',
          fontSize: 16,
          lineHeight: 1.5,
        },
        outlined: {
          padding: '15px 23px',
          backgroundColor: '#fff',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: `1px solid ${grey[300]}`,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '1000px',
          padding: '0 24px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          maxWidth: 'sm',
          padding: '16px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '16px 24px 32px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontColor: grey[600],
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '16px 24px 8px',
          fontSize: '18px',
          fontWeight: 600,
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          fontWeight: 600,
          color: '#0070D7',
        },
        button: {
          fontSize: '1rem',
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        elevation: {
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: 'filled',
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: '0 24px',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h1',
          h3: 'h2',
          h4: 'h3',
          h5: 'h4',
          body3: 'p',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 18,
          padding: 24,
          '&:first-of-type': {
            paddingLeft: 0,
          },
          '&:last-of-type': {
            paddingRight: 0,
          },
        },
        body: {
          color: grey[600],
        },
        head: {
          fontWeight: 600,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: 'text.primary',
          marginBottom: 0,
        },
      },
    },
  },
});
