'use client';

import * as React from 'react';
import { GlobalStyles } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { publicMotivoTheme } from ':src/theme/publicMotivoTheme';
import EmotionCacheProvider from './EmotionCache';

const globalStyles = (
  <GlobalStyles
    styles={{
      html: {
        '-webkit-font-smoothing': 'auto',
      },
      body: {
        fontFamily: ['proxima-nova', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
        fontWeight: 500,
        color: '#444243',
        margin: 0,
      },
      a: {
        textDecoration: 'none',
        color: 'unset',
      },
      strong: {
        fontWeight: 600,
      },

      '.StripeElement': {
        padding: '16px',
        border: '1px solid #BBB9BA',
        borderRadius: '4px',
      },
      '.StripeElement--focus': {
        padding: '15px',
        border: '2px solid #30A08F',
      },
      '.StripeElement--invalid': {
        borderColor: '#E46252 !important',
      },
      '.SnackbarContainer-root': {
        width: 'unset !important',
      },
      '.SnackbarItem-formSaved': {
        minWidth: 'unset !important',
        width: 'fit-content',
        backgroundColor: '#1A8F7D !important',
        fontWeight: '700 !important' as any,
      },
    }}
  />
);

export default function ThemeRegistry({ children }: { children: React.ReactNode }) {
  return (
    <EmotionCacheProvider options={{ key: 'mui' }}>
      {globalStyles}
      <ThemeProvider theme={publicMotivoTheme}>{children}</ThemeProvider>
    </EmotionCacheProvider>
  );
}
