export default function LogoLetterM({ height = 30, color = '#1A8F7D' }) {
  return (
    <svg height={height} viewBox="0 0 112 93" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.484497 37.3989C6.05324 28.0791 17.8485 27.4806 24.2293 32.1067C27.6918 34.6166 29.608 38.1223 30.5646 41.8609C32.9689 39.1186 35.0637 35.9994 37.8381 33.6989C47.184 25.9499 60.898 27.9686 65.0304 42.589C66.496 40.5139 67.7257 38.4207 69.2928 36.6213C74.0204 31.1942 79.9058 28.3467 87.2252 29.5541C93.9334 30.6614 99.6512 37.5551 100.024 45.339C100.44 54.013 100.268 62.7145 100.643 71.391C100.849 76.1489 102.157 80.5671 107.013 82.9473C109.102 83.9713 107.799 85.4077 107.012 86.3968C103.971 90.2171 99.9189 92.4093 95.061 92.6848C84.5845 93.2791 77.0714 86.3124 76.8402 75.7754C76.6777 68.4046 76.8137 61.0275 76.7548 53.6535C76.7424 52.0853 76.648 50.4729 76.2709 48.9603C75.3646 45.3229 72.1913 44.4598 69.5465 47.1286C66.8752 49.8234 65.7918 53.2734 65.8392 56.9562C65.972 67.3098 66.1886 77.6645 66.5782 88.0114C66.66 90.1859 66.1485 90.6859 64.0657 90.652C56.8824 90.5353 49.6965 90.6072 42.1345 90.6072C42.1345 89.4874 42.1345 88.5588 42.134 87.6296C42.1288 76.3046 42.1392 64.9801 42.0976 53.6551C42.0918 52.1797 42.11 50.5879 41.5834 49.2625C41.0564 47.9363 39.9594 46.0265 38.9318 45.9057C37.4976 45.7369 35.47 46.601 34.449 47.7139C31.8689 50.5265 31.0908 54.1921 31.0714 57.9478C31.0225 67.7801 31.047 77.613 31.0424 87.4452C31.0418 88.3895 31.0424 89.3338 31.0424 90.4411C22.9209 90.4411 15.0585 90.4411 6.76939 90.4411C6.76939 89.5775 6.75638 88.6609 6.77148 87.7452C6.96419 76.1603 7.19387 64.5765 7.32408 52.991C7.34648 50.9931 7.05376 48.9729 6.72304 46.9931C6.07876 43.1333 4.03346 40.2265 0.484497 38.453C0.484497 38.1015 0.484497 37.7505 0.484497 37.3989"
        fill={color}
      />
    </svg>
  );
}
