'use client';

import { ReactNode } from 'react';
import { styled } from '@mui/material';

const Component = styled('div', {
  shouldForwardProp: (prop) => !['x', 'y'].includes(prop as string),
})<{ x?: number; y?: number }>(({ theme, x, y }) => ({
  '&& > * + *': {
    ...(x
      ? {
          marginLeft: theme.spacing(x),
        }
      : {}),
    ...(y
      ? {
          marginTop: theme.spacing(y),
        }
      : {}),
  },
}));

export type SpacingProps = {
  children: ReactNode;
} & (
  | {
      x: number;
    }
  | {
      y: number;
    }
);

export default function Spacing({ children, ...props }: SpacingProps) {
  return (
    <Component x={'x' in props ? props.x : undefined} y={'y' in props ? props.y : undefined}>
      {children}
    </Component>
  );
}
