import { PaletteColorOptions } from '@mui/material';
import { ColorPartial, TypeText } from '@mui/material/styles/createPalette';

export const green: ColorPartial = {
  900: '#0A4738',
  800: '#106353',
  700: '#147361',
  600: '#178370',
  500: '#1A8F7D',
  400: '#30A08F',
  300: '#51B1A2',
  200: '#81C7BC',
  100: '#B2DDD6',
  50: '#E0F1EF',
};

export const primary: PaletteColorOptions = {
  main: green[400] as string,
};

export const blue: ColorPartial = {
  900: '#265F81',
  800: '#317FA2',
  700: '#3590B6',
  600: '#3FA3C8',
  500: '#47B1D6',
  400: '#57BDD9',
  300: '#6DC8DE',
  200: '#8FD8E6',
  100: '#B9E8EF',
  50: '#E3F4F9',
};

export const gold: ColorPartial = {
  900: '#C25F3D',
  800: '#CD7447',
  700: '#D6804B',
  600: '#DF8D50',
  500: '#E49754',
  400: '#EAA45E',
  300: '#EEB373',
  200: '#F3C897',
  100: '#F7DEBF',
  50: '#FCF2E5',
};

export const warning: PaletteColorOptions = {
  900: '#463B24',
  main: '#EDC676',
  50: '#FBF4E4',
};

export const error: PaletteColorOptions = {
  900: '#5A1F19',
  main: '#E46252',
  50: '#FAEDEB',
};

export const success: PaletteColorOptions = {
  dark: '#008237',
  main: '#4AC072',
  light: '#C2E8CC',
};

export const info: PaletteColorOptions = {
  900: '#204162 ',
  main: '#61A4EF',
  50: '#EAF4FC',
};

export const grey: ColorPartial = {
  900: '#1F1D1E',
  800: '#444243',
  700: '#5F5D5E',
  600: '#737072',
  500: '#9B999A',
  400: '#BBB9BA',
  300: '#DEDCDD',
  200: '#EDEBEC',
  100: '#FBF9FA',
  50: '#FCF9FB',
};

export const textColors: TypeText = {
  primary: grey[800] as string,
  secondary: grey[600] as string,
  tertiary: grey[400] as string,
  disabled: grey[300] as string,
};

export const neutral: PaletteColorOptions = {
  main: textColors.primary,
  contrastText: '#fff',
};
